<template>
  <v-container class="pa-10" fluid>
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-col cols="12">
            <v-card class="mt-10 mb-10">
              <v-data-table
                :headers="headers"
                :items="contract"
                :loading="loading"
                loading-text="Please wait..."
                no-data-text="No Specification available"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar elevation="2" style="background: #f5f5f5">
                    <!-- <v-toolbar-title
                      style="margin-left: -14px"
                      class="mt-2 mb-2"
                      >Product Specification
                    </v-toolbar-title> -->
                    <v-row>
                      <p
                        class="mt-5 mb-2 ml-1 font-weight-bold d-inline-block text-truncate"
                        style="max-width: 500px"
                      >
                        Product Name : {{ product_title }}
                      </p>
                      <!-- <p style="margin-left: 224px" class="mt-5 mb-2">
                        Product code : {{ product_id }}
                      </p> -->
                      <v-spacer></v-spacer>

                      <v-btn
                        color="primary"
                        dark
                        class="mb-2 mt-2 mr-2"
                        @click="addnew"
                        elevation="0"
                      >
                        <v-icon>mdi-plus</v-icon>
                        Add Specification
                      </v-btn>

                      <v-btn
                        color="primary"
                        @click="viewAllShared"
                        class="mb-2 mt-2 mr-2"
                      >
                        Shared Info
                      </v-btn>
                    </v-row>
                  </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2" @click="assignEditItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>

                <template v-slot:item.barcode="{ item }">
                  <v-btn
                    text
                    @click="ViewBarcode(item)"
                    color="primary"
                    style="
                      padding-left: 0 !important;
                      padding-right: 0 !important;
                    "
                  >
                    {{ item.SKU }}
                  </v-btn>
                </template>

                <template v-slot:item.adquantity="{ item }" class="px-0">
                  <v-btn
                    text
                    @click="ADDQuantity(item)"
                    color="primary"
                    class="px-0"
                    style="
                      padding-left: 0 !important;
                      padding-right: 0 !important;
                    "
                  >
                    <!-- <v-icon small class="mr-2"> mdi-plus </v-icon>  -->
                    Quantity & Price
                  </v-btn>
                </template>

                <template v-slot:item.sharemother="{ item }">
                  <div v-if="item.purchase_price !== '0.00'">
                    <v-btn
                      v-if="item.shared == false"
                      text
                      @click="ShareMS(item)"
                      color="primary"
                      style="
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                      "
                    >
                      Share
                    </v-btn>
                    <v-btn
                      v-else
                      text
                      @click="ShareUpdate(item)"
                      color="primary"
                      style="
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                      "
                    >
                      Update & Share
                    </v-btn>
                  </div>
                </template>
                <!-- <template v-slot:item.shareStatus="{ item }">
                  <p v-if="(item.shared = 'true')" class="mb-0">Yes</p>
                  <p v-else class="mb-0">No</p>
                </template> -->
                <template v-slot:item.status="{ item }">
                  <v-btn
                    text
                    small
                    ripple
                    color="red"
                    v-if="item.specification_status == 'Published'"
                    @click="action(item)"
                    style="padding-left: 0 !important"
                  >
                    Unpublish</v-btn
                  >
                  <v-btn
                    text
                    small
                    ripple
                    color="primary"
                    v-else
                    @click="action(item)"
                    style="padding-left: 0 !important"
                  >
                    Publish</v-btn
                  >
                </template>
                <!-- <template v-slot:item.addprice="{ item }">
                  <v-btn text @click="AddPrice(item)" color="primary">
                    <v-icon small class="mr-2"> mdi-plus </v-icon> price
                  </v-btn>
                </template> -->
              </v-data-table>
            </v-card>
          </v-col>

          <!-- Edit Specification -->
          <v-dialog v-model="dialog" width="750px" scrollable>
            <v-card class="my-0">
              <v-card-title class="font-weight-bold"
                >Edit Specification
              </v-card-title>

              <v-card-text class="pb-0" style="height: 500px">
                <v-form ref="editSpecificationForm" v-model="validEditForm">
                  <v-row>
                    <v-col>
                      <v-select
                        dense
                        v-model="editedItem.unit"
                        :items="select_val"
                        label="Product unit type"
                        :rules="[(v) => !!v || 'Unit is required']"
                        outlined
                      ></v-select>
                    </v-col>

                    <v-col class="d-flex flex-row align-center">
                      <v-text-field
                        v-model="editedItem.weight"
                        outlined
                        dense
                        label="Weight"
                        :rules="[(v) => !!v || 'Weight is required']"
                      >
                        <p class="mb-0 mt-1 font-weight-bold" slot="append">
                          /{{ editedItem.unit }}
                        </p>
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <!-- <v-row>
                    <v-col>
                      <v-text-field
                        v-model="editedItem.SKU"
                        label="Product SKU code"
                        outlined
                        dense
                        maxlength="4"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="editedItem.price.purchase_price"
                        label="Product Purchase Price"
                        required
                        type="number"
                        :rules="[(v) => !!v || 'Purchase Price is required']"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="editedItem.price.price"
                        label="Product Selling Price"
                        required
                        type="number"
                        :rules="[(v) => !!v || 'Selling Price is required']"
                        outlined
                        dense
                      ></v-text-field
                    ></v-col>
                  </v-row> -->

                  <v-row>
                    <v-col>
                      <v-select
                        :items="colors"
                        label="Color"
                        dense
                        v-model="editedItem.color"
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="sizes"
                        label="Size"
                        dense
                        v-model="editedItem.size"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-menu
                        v-model="menufecmenu"
                        :close-on-content-click="false"
                        :nudge-right="0"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedItem.manufacture_date"
                            label="Product manufacture Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.manufacture_date"
                          @input="menufecmenu = false"
                          class="ma-0"
                          no-title
                          scrollable
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col>
                      <v-menu
                        v-model="expmenu"
                        :close-on-content-click="false"
                        :nudge-right="0"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedItem.expire"
                            label="Product Expire Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.expire"
                          @input="expmenu = false"
                          class="ma-0"
                          no-title
                          scrollable
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        dense
                        v-model="editedItem.warranty_unit"
                        :items="warranty_types"
                        label="Warranty type"
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="editedItem.warranty"
                        label="Warranty Amount"
                        type="number"
                        outlined
                        dense
                        :disabled="editedItem.warranty_unit == 'none'"
                      >
                        <p slot="append" class="mb-0 mt-1 font-weight-bold">
                          /{{ editedItem.warranty_unit }}
                        </p>
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      ><v-text-field
                        v-model="editedItem.vat"
                        label="VAT(%)"
                        required
                        type="number"
                        outlined
                        dense
                      ></v-text-field
                    ></v-col>
                    <v-col
                      ><v-text-field
                        v-model="editedItem.point.point"
                        label="Point Amount Per Hundred"
                        required
                        type="number"
                        outlined
                        dense
                      ></v-text-field
                    ></v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-menu
                        v-model="menu7"
                        :close-on-content-click="false"
                        :nudge-right="0"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedItem.point.start_date"
                            label="Point Start Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                            :disabled="editedItem.point.point == ''"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.point.start_date"
                          :min="new Date().toISOString().substr(0, 10)"
                          @input="menu7 = false"
                          class="ma-0"
                          no-title
                          scrollable
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-menu
                        v-model="menu8"
                        :close-on-content-click="false"
                        :nudge-right="0"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedItem.point.end_date"
                            label="Point End Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                            :disabled="editedItem.point.point == ''"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.point.end_date"
                          :min="editedItem.point.start_date"
                          @input="menu8 = false"
                          class="ma-0"
                          no-title
                          scrollable
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-select
                        dense
                        v-model="editedItem.discount.discount_type"
                        :items="discount_types"
                        label="Discount type"
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="editedItem.discount.amount"
                        label="Discount Amount"
                        required
                        type="number"
                        outlined
                        dense
                        :disabled="editedItem.discount.discount_type == 'none'"
                      >
                        <v-icon
                          slot="append"
                          color="red"
                          v-if="editedItem.discount.discount_type == 'amount'"
                          >mdi-currency-bdt</v-icon
                        >
                        <v-icon
                          slot="append"
                          color="red"
                          v-if="
                            editedItem.discount.discount_type == 'percentage'
                          "
                          >mdi-percent</v-icon
                        >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-menu
                        v-model="menu5"
                        :close-on-content-click="false"
                        :nudge-right="0"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedItem.discount.start_date"
                            label="Discount Start Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                            :disabled="editedItem.discount_type == 'none'"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.discount.start_date"
                          :min="new Date().toISOString().substr(0, 10)"
                          @input="menu5 = false"
                          class="ma-0"
                          no-title
                          scrollable
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-menu
                        v-model="menu6"
                        :close-on-content-click="false"
                        :nudge-right="0"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedItem.discount.end_date"
                            label="Discount End Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                            :disabled="editedItem.discount_type == 'none'"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.discount.end_date"
                          :min="editedItem.discount.start_date"
                          @input="menu6 = false"
                          class="ma-0"
                          no-title
                          scrollable
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-card style="min-width: 100%; border: 1px solid black">
                      <v-card-title class="font-weight-medium pt-2"
                        >For Delivery</v-card-title
                      >
                      <v-card-text class="pb-0">
                        <v-row>
                          <v-col>
                            <v-select
                              dense
                              v-model="editedItem.delivery_info.measument_unit"
                              :items="delivery_units"
                              label="Product unit type"
                              :rules="[(v) => !!v || 'Unit is required']"
                              outlined
                            ></v-select>
                          </v-col>

                          <v-col>
                            <v-text-field
                              v-model="editedItem.delivery_info.weight"
                              outlined
                              dense
                              label="Weight"
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="editedItem.delivery_info.height"
                              outlined
                              dense
                              label="Height"
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="editedItem.delivery_info.width"
                              outlined
                              dense
                              label="Width"
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="editedItem.delivery_info.length"
                              outlined
                              dense
                              label="Length"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-checkbox
                              v-model="editedItem.delivery_info.delivery_free"
                              label="Free Delivery ?"
                              color="red darken-3"
                              hide-details
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-radio-group
                              v-model="editedItem.option"
                              row
                              @change="
                                deliveryOptionChangedOnedit(editedItem.option)
                              "
                            >
                              <v-radio
                                label="All Over Bangladesh"
                                value="all"
                              ></v-radio>
                              <v-radio
                                label="Select Districts"
                                value="manual"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                        <div v-show="editedItem.option == 'manual'">
                          <v-row
                            v-for="(delivery, index) in EditArrayForDelivery"
                            :key="index"
                          >
                            <v-col cols="3">
                              <v-select
                                :items="districts"
                                item-text="Area_name"
                                :menu-props="{ top: true, offsetY: true }"
                                :disabled="delivery.active"
                                label="Select District"
                                solo
                                v-model="delivery.selectedDistrict"
                                @change="
                                  onSelectDistrictOnEdit(delivery, index)
                                "
                                dense
                              ></v-select>
                            </v-col>
                            <v-col>
                              <v-select
                                v-model="delivery.selectedThana"
                                :items="delivery.thanas"
                                item-text="location_name"
                                :menu-props="{ top: true, offsetY: true }"
                                :disabled="delivery.active"
                                chips
                                dense
                                label="Select Thanas"
                                multiple
                                solo
                              >
                                <template v-slot:selection="{ item, index }">
                                  <v-chip v-if="index < 3">
                                    <span>{{ item.location_name }}</span>
                                  </v-chip>
                                  <span
                                    v-if="index == 3"
                                    class="grey--text caption"
                                  >
                                    (+{{ delivery.selectedThana.length - 3 }}
                                    others)
                                  </span>
                                </template>
                              </v-select>
                            </v-col>

                            <v-col cols="2">
                              <v-row>
                                <v-btn
                                  fab
                                  small
                                  color="primary"
                                  :disabled="
                                    EditArrayForDelivery.length ==
                                    districts.length
                                  "
                                  @click="increaseDeliveryLengthOnedit(index)"
                                >
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                                <v-btn
                                  fab
                                  small
                                  color="red"
                                  :disabled="EditArrayForDelivery.length == 1"
                                  @click="deleteDeliveryLengthOnedit(index)"
                                >
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-row>
                            </v-col>
                          </v-row>
                        </div>

                        <!-- <v-row>
                          <v-col>
                            <v-text-field
                              v-model="editedItem.delivery_info.charge_inside"
                              outlined
                              dense
                              label="Inside City Chrage"
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="editedItem.delivery_info.charge_outside"
                              outlined
                              dense
                              label="Outside City Chrage"
                              type="number"
                            ></v-text-field>
                          </v-col>
                        </v-row> -->
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <!-- <v-btn color="success" elevation="0" class="ml-3"
                  >Add New</v-btn
                > -->
                <v-spacer></v-spacer>

                <v-btn
                  color="#854FFF"
                  @click="saveEdit"
                  :disabled="!validEditForm"
                  >Save Edit</v-btn
                >
                <v-btn color="pink" @click="dialog = false">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- ADD Specification -->
          <v-dialog v-model="addDialog" width="750px" scrollable>
            <v-card class="my-0">
              <v-card-title class="font-weight-medium pt-2">
                Add Specification
              </v-card-title>

              <v-card-text class="pb-0" style="height: 500px">
                <v-form
                  ref="specificationForm"
                  v-model="valid_add_specification"
                >
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="addItem.SKU"
                        label="Product SKU code"
                        outlined
                        dense
                        maxlength="4"
                        :rules="[
                          (v) => {
                            if (v.length != 0 && v.length < 4) {
                              return '4 characters required';
                            }
                            return true;
                          },
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-select
                        dense
                        v-model="addItem.product_unit"
                        :items="select_val"
                        label="Product unit type"
                        :rules="[(v) => !!v || 'Unit is required']"
                        outlined
                      ></v-select>
                    </v-col>

                    <v-col>
                      <v-text-field
                        v-model="addItem.weight"
                        outlined
                        dense
                        label="Amount (*)"
                        type="number"
                        :rules="[(v) => !!v || 'Weight is required']"
                      >
                        <p class="mb-0 mt-1 font-weight-bold" slot="append">
                          /{{ addItem.product_unit }}
                        </p></v-text-field
                      >
                    </v-col>
                  </v-row>

                  <!-- <v-row>
                    <v-col>
                      <v-text-field
                        v-model="addItem.SKU"
                        label="Product SKU code"
                        outlined
                        dense
                        maxlength="4"
                        :rules="[
                          (v) => {
                            if (v.length != 0 && v.length < 4) {
                              return '4 characters required';
                            }
                            return true;
                          },
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="addItem.purchase_price"
                        label="Product Purchase Price"
                        required
                        type="number"
                        :rules="[(v) => !!v || 'Purchase Price is required']"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="addItem.price"
                        label="Product Selling Price"
                        required
                        type="number"
                        :rules="[(v) => !!v || 'Selling Price is required']"
                        outlined
                        dense
                      ></v-text-field
                    ></v-col>
                  </v-row> -->

                  <v-row>
                    <v-col>
                      <v-select
                        :items="colors"
                        label="Color"
                        dense
                        v-model="addItem.color"
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="sizes"
                        label="Size"
                        dense
                        v-model="addItem.size"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-menu
                        v-model="menufecmenu"
                        :close-on-content-click="false"
                        :nudge-right="0"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="addItem.manufacture_date"
                            label="Product Manufacture Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="addItem.manufacture_date"
                          @input="menufecmenu = false"
                          class="ma-0"
                          no-title
                          scrollable
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col>
                      <v-menu
                        v-model="expmenu"
                        :close-on-content-click="false"
                        :nudge-right="0"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="addItem.expire"
                            label="Product Expire Date "
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="addItem.expire"
                          :min="addItem.manufacture_date"
                          @input="expmenu = false"
                          class="ma-0"
                          no-title
                          scrollable
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-select
                        v-model="addItem.warranty_unit"
                        :items="warranty_types"
                        label="Warranty Type"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="addItem.warranty"
                        label="Warranty"
                        type="number"
                        outlined
                        dense
                      >
                        <p slot="append" class="mb-0 mt-1 font-weight-bold">
                          /{{ addItem.warranty_unit }}
                        </p>
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="addItem.vat"
                        label="VAT(%)"
                        required
                        type="number"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="addItem.point_amount"
                        label="Point Amount Per Hundred"
                        required
                        type="number"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="0"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="addItem.point_start_date"
                            label="Point Start Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                            :disabled="addItem.point_amount == ''"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="addItem.point_start_date"
                          :min="new Date().toISOString().substr(0, 10)"
                          @input="menu3 = false"
                          class="ma-0"
                          no-title
                          scrollable
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-menu
                        v-model="menu4"
                        :close-on-content-click="false"
                        :nudge-right="0"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="addItem.point_end_date"
                            label="Point End Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                            :disabled="addItem.point_amount == ''"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="addItem.point_end_date"
                          :min="addItem.point_start_date"
                          @input="menu4 = false"
                          class="ma-0"
                          no-title
                          scrollable
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-select
                        dense
                        v-model="addItem.discount_type"
                        :items="discount_types"
                        label="Discount type"
                        :rules="[(v) => !!v || 'Unit is required']"
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="addItem.discount_amount"
                        label="Discount Amount"
                        type="number"
                        outlined
                        dense
                        :disabled="addItem.discount_type == 'none'"
                      >
                        <v-icon
                          slot="append"
                          color="red"
                          v-if="addItem.discount_type == 'amount'"
                          >mdi-currency-bdt</v-icon
                        >
                        <v-icon
                          slot="append"
                          color="red"
                          v-if="addItem.discount_type == 'percentage'"
                          >mdi-percent</v-icon
                        >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="0"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="addItem.discount_start_date"
                            label="Discount Start Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                            :disabled="addItem.discount_type == 'none'"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="addItem.discount_start_date"
                          :min="new Date().toISOString().substr(0, 10)"
                          @input="menu = false"
                          class="ma-0"
                          no-title
                          scrollable
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="0"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="addItem.discount_end_date"
                            label="Discount End Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                            :disabled="addItem.discount_type == 'none'"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="addItem.discount_end_date"
                          :min="addItem.discount_start_date"
                          @input="menu2 = false"
                          class="ma-0"
                          no-title
                          scrollable
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-card style="min-width: 100%; border: 1px solid black">
                      <v-card-title class="font-weight-medium pt-2"
                        >For Delivery</v-card-title
                      >
                      <v-card-text>
                        <v-row>
                          <v-col>
                            <v-select
                              dense
                              v-model="addItem.delivery_product_unit"
                              :items="delivery_units"
                              label="Product unit type"
                              :rules="[(v) => !!v || 'Unit is required']"
                              outlined
                            ></v-select>
                          </v-col>

                          <v-col>
                            <v-text-field
                              v-model="addItem.delivery_weight"
                              type="number"
                              outlined
                              dense
                              label="Weight (*)"
                              :rules="[(v) => !!v || 'Weight is required']"
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="addItem.delivery_height"
                              type="number"
                              outlined
                              dense
                              label="Height in cm"
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="addItem.delivery_width"
                              type="number"
                              outlined
                              dense
                              label="Width in cm"
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="addItem.delivery_length"
                              type="number"
                              outlined
                              dense
                              label="Length in cm"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-checkbox
                              v-model="addItem.delivery_free"
                              label="Free Delivery ?"
                              color="red darken-3"
                              hide-details
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-radio-group
                              v-model="addItem.option"
                              row
                              @change="deliveryOptionChanged(addItem.option)"
                            >
                              <v-radio
                                label="All Over Bangladesh"
                                value="all"
                              ></v-radio>
                              <v-radio
                                label="Select Districts"
                                value="manual"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                        <div v-show="addItem.option == 'manual'">
                          <v-row
                            v-for="(
                              delivery, index
                            ) in addItem.arrayForDelivery"
                            :key="index"
                          >
                            <v-col cols="3">
                              <v-select
                                :items="districts"
                                item-text="Area_name"
                                :menu-props="{ top: true, offsetY: true }"
                                :disabled="delivery.active"
                                label="Select District"
                                solo
                                v-model="delivery.selectedDistrict"
                                @change="onSelectDistrict(delivery, index)"
                                dense
                              ></v-select>
                            </v-col>
                            <v-col>
                              <v-select
                                v-model="delivery.selectedThana"
                                :items="delivery.thanas"
                                item-text="location_name"
                                :menu-props="{ top: true, offsetY: true }"
                                :disabled="delivery.active"
                                chips
                                dense
                                label="Select Thanas"
                                multiple
                                solo
                              >
                                <template v-slot:selection="{ item, index }">
                                  <v-chip v-if="index < 3">
                                    <span>{{ item.location_name }}</span>
                                  </v-chip>
                                  <span
                                    v-if="index == 3"
                                    class="grey--text caption"
                                  >
                                    (+{{ delivery.selectedThana.length - 3 }}
                                    others)
                                  </span>
                                </template>
                              </v-select>
                            </v-col>

                            <v-col cols="2">
                              <!-- index + 1 ==
                                      addItem.arrayForDelivery.length && -->
                              <v-row>
                                <v-btn
                                  fab
                                  small
                                  color="primary"
                                  :disabled="
                                    addItem.arrayForDelivery.length ==
                                    districts.length
                                  "
                                  @click="increaseDeliveryLength(index)"
                                >
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                                <v-btn
                                  fab
                                  small
                                  color="red"
                                  :disabled="
                                    addItem.arrayForDelivery.length == 1
                                  "
                                  @click="deleteDeliveryLength(index)"
                                >
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-row>
                            </v-col>
                          </v-row>
                        </div>
                        <!-- <v-row>
                          <v-col>
                            <v-text-field
                              v-model="addItem.delivery_inside_city_charge"
                              outlined
                              dense
                              label="Inside City Chrage"
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="addItem.delivery_outside_city_charge"
                              outlined
                              dense
                              label="Outside City Chrage"
                              type="number"
                            ></v-text-field>
                          </v-col>
                        </v-row> -->
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <!-- <v-btn color="#854FFF" elevation="0" class="ml-3"
                  >Add New</v-btn
                >       :rules="[(v) => v.length >= 4 && v.length != 0|| 'Min 4 characters']"        -->
                <v-spacer></v-spacer>
                <v-btn color="red" @click="addDialog = false" elevation="0"
                  >Cancel</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  elevation="0"
                  @click="save('Pending')"
                  :disabled="!valid_add_specification"
                >
                  Upload without publish
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  @click="save('Published')"
                  elevation="0"
                  :disabled="!valid_add_specification"
                >
                  Upload & publish</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Add quantity and price for this product -->
          <v-dialog v-model="addquantitydialog" width="750px" scrollable>
            <v-card>
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>{{ product_title }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  elevation="0"
                  dark
                  small
                  class="mr-2"
                  @click="addquantitydialog = false"
                >
                  <p class="mb-0 font-weight-medium text-body-2">Cancel</p>
                </v-btn>
                <v-btn
                  color="white"
                  elevation="0"
                  @click="confirmquantity"
                  :disabled="!priceValidationForm"
                  small
                >
                  <p class="green--text mb-0 font-weight-medium text-body-2">
                    Confirm
                  </p>
                </v-btn>
              </v-toolbar>

              <v-divider></v-divider>
              <v-card-text class="pt-0">
                <v-card-title class="py-2 px-0 font-weight-bold"
                  >Price Information</v-card-title
                >

                <v-form v-model="priceValidationForm" ref="priceForm">
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="price.purchase_price"
                        label="Purchase Price"
                        required
                        type="number"
                        :rules="[(v) => !!v || 'Purchase Price is required']"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="price.price"
                        label="Selling Price"
                        required
                        type="number"
                        :rules="[(v) => !!v || 'Selling Price is required']"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>

                <v-card-title class="py-2 px-0 font-weight-bold"
                  >Warehouse</v-card-title
                >
                <v-divider></v-divider>
                <v-simple-table fixed-header height="300px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Warehouse Name</th>
                        <th class="text-left">Previous Quantity</th>
                        <th class="text-center">Add Quantity</th>
                        <!-- <th class="text-left">Action</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in warehouse" :key="index">
                        <td>{{ item.warehouse_name }}</td>
                        <td>{{ item.previous_quantity }}</td>
                        <td class="d-flex justify-center">
                          <v-form lazy-validation>
                            <v-text-field
                              outlined
                              type="number"
                              style="max-width: 100px; height: 50px"
                              class="mt-2"
                              flat
                              dense
                              v-model="item.quantity"
                              @input="warehouseAdd(item)"
                            ></v-text-field>
                          </v-form>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <v-divider></v-divider>
                <v-card-title class="py-2 px-0 font-weight-bold"
                  >Shop</v-card-title
                >
                <v-divider></v-divider>

                <v-simple-table fixed-header height="300px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Shop Name</th>
                        <th class="text-left">Previous Quantity</th>
                        <th class="text-center">Add Quantity</th>
                        <!-- <th class="text-left">Action</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in shop" :key="index">
                        <td>{{ item.shop_name }}</td>
                        <td class="text-left">{{ item.previous_quantity }}</td>
                        <td class="d-flex justify-center">
                          <v-form>
                            <v-text-field
                              outlined
                              type="number"
                              style="max-width: 100px; height: 50px"
                              class="mt-2"
                              flat
                              dense
                              v-model="item.quantity"
                              @input="shopAdd(item)"
                            ></v-text-field>
                          </v-form>
                        </td>
                        <!-- <td>
                  <v-btn
                    outlined
                    icon
                    :disabled="
                      !(Number(item.subtract) > 0) ||
                      Number(item.subtract) > Number(item.quantity)
                    "
                    @click="shopAdd(item)"
                    ><v-icon>mdi-plus</v-icon></v-btn
                  >
                </td> -->
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>

              <!-- <v-card-actions>

                <v-btn elevation="0" @click="confirmquantity"> Confirm </v-btn>
              </v-card-actions> -->
            </v-card>
          </v-dialog>

          <!-- Mother Site Share for this product -->
          <v-dialog v-model="ShareProductmodel" width="750px">
            <v-card>
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title
                  >Product Name: {{ product_title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>

              <v-divider></v-divider>
              <v-card-text class="pt-0">
                <v-card-title class="py-2 px-0 font-weight-bold"
                  >Price Assign For Share Product</v-card-title
                >
                <v-divider></v-divider>
                <v-simple-table fixed-header height="300px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Quantity</th>
                        <th class="text-left">Status</th>
                        <th class="text-center">Purchase Price</th>
                        <th class="text-center">Selling Price</th>
                        <!-- <th class="text-left">Action</th> -->
                      </tr>
                    </thead>
                    <tbody align="center">
                      <tr v-for="(item, index) in specprice" :key="index">
                        <td class="text-left">
                          <v-form>
                            <v-text-field
                              v-if="index"
                              outlined
                              type="number"
                              style="max-width: 100px; height: 50px"
                              class="mt-2"
                              flat
                              dense
                              v-model="item.quantity"
                            ></v-text-field>
                            <p v-else>1</p>
                          </v-form>
                        </td>
                        <td class="text-left">{{ item.status }}</td>

                        <td>
                          <p>{{ temp.purchase_price }}</p>

                          <!-- <v-form>
                            <v-text-field
                              outlined
                              type="number"
                              style="max-width: 99px; height: 50px"
                              class="mt-2"
                              flat
                              dense
                              v-model="item.purchase_price"
                            ></v-text-field>
                          </v-form> -->
                        </td>
                        <td>
                          <v-form>
                            <v-text-field
                              outlined
                              type="number"
                              style="max-width: 100px; height: 50px"
                              class="mt-2"
                              flat
                              dense
                              v-model="item.selling_price"
                            ></v-text-field>
                          </v-form>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>

              <v-card-actions>
                <v-row>
                  <v-col cols="5" class="d-flex flex-row">
                    <p class="pt-5 px-2 font-weight-medium">MRP:</p>
                    <v-text-field
                      outlined
                      type="number"
                      style="max-width: 150px; height: 50px"
                      class="mt-2"
                      flat
                      dense
                      v-model="mrp"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="4" class="d-flex flex-row mt-2">
                    <!-- <v-btn elevation="0" color="#854FFF" class="pt-2" @click="UpdateShare">
                      Update And Share
                    </v-btn> -->
                    <v-btn elevation="0" color="red" @click="Cancelshare">
                      Cancel
                    </v-btn>
                    <v-btn
                      elevation="0"
                      color="primary"
                      @click="SaveShare(temp)"
                    >
                      Share
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Mother Site Share Update for this product -->
          <v-dialog v-model="shareup" width="750px">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title
                >Product Name: {{ product_title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>

            <v-divider></v-divider>
            <v-card class="pa-5">
              <v-card-text class="pt-0">
                <v-card-title class="py-2 px-0 font-weight-bold"
                  >Price Assign For Share Product</v-card-title
                >

                <v-divider></v-divider>
                <v-form ref="shareupdatePrice" v-model="valid_update_share">
                  <v-simple-table fixed-header height="250px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Quantity</th>
                          <th class="text-left">Status</th>
                          <th class="text-center">Purchase Price</th>
                          <th class="text-center">Selling Price</th>
                        </tr>
                      </thead>
                      <tbody align="center">
                        <tr
                          v-for="(item, index) in child_details.max_min"
                          :key="index"
                        >
                          <td class="text-left">{{ item.quantity }}</td>
                          <td class="text-left">{{ item.status }}</td>
                          <td>{{ item.purchase_price }}</td>

                          <td>
                            <v-form>
                              <v-text-field
                                outlined
                                type="number"
                                style="max-width: 100px; height: 50px"
                                class="mt-2"
                                flat
                                dense
                                v-model="item.selling_price"
                              ></v-text-field>
                            </v-form>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-card-actions>
                    <v-row>
                      <v-col cols="5" class="d-flex flex-row">
                        <p
                          v-if="
                            child_details.max_min &&
                            child_details.max_min.length > 0
                          "
                          class="mt-5 mr-2 font-weight-medium d-flex flex-row"
                        >
                          MRP :
                        </p>
                        <v-text-field
                          v-if="
                            child_details.max_min &&
                            child_details.max_min.length > 0
                          "
                          outlined
                          type="number"
                          style="max-width: 100px; height: 50px"
                          class="mt-2"
                          flat
                          dense
                          required
                          v-model="child_details.max_min[0].mrp"
                          label="New MRP"
                        ></v-text-field>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="d-flex flex-row mt-2">
                        <v-btn
                          elevation="0"
                          color="red"
                          @click="CancelupdateShare(child_details)"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          @click="SaveUpdateShare(child_details)"
                          elevation="0"
                          color="success"
                          :disabled="!valid_update_share"
                        >
                          Save
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>



<script>
export default {
  data: () => ({
    priceValidationForm: false,
    product_title: "",
    product_id: "",
    shareup: false,
    dialog: false,
    dialog2: false,
    ShareProductmodel: false,
    valid_update_share: false,

    menu: false,
    menu2: false,
    menu3: false,
    menu4: false,
    menu5: false,
    menu6: false,
    menu7: false,
    menu8: false,
    expmenu: false,
    menufecmenu: false,
    loading: true,
    addquantitydialog: false,
    share_p_price: "",
    share_s_price: "",
    shareqty: "",
    shop: [],
    warehouse: [],
    size: "",
    color: "",
    unit: "",
    units: [],
    discount_types: ["none", "amount", "percentage"],
    sizes: ["none", "S", "M", "L", "XL", "XXL", "XXXL"],
    select_val: [
      "kg",
      "gm",
      "pcs",
      "dozen",
      "liter",
      "ml",
      "inch",
      "cm",
      "mm",
      "ft",
    ],
    delivery_units: ["kg", "gm", "mg"],
    warranty_types: ["none", "day", "month", "year"],
    colors: [
      "none",
      "white",
      "deep-purple",
      "indigo",
      "yellow",
      "light-blue",
      "cyan",
      "orange",
      "red",
      "teal",
      "light-green",
      "pink",
      "purple",
      "blue",
      "lime",
      "amber",
      "deep-orange",
      "green",
      "blue-grey",
      "brown",
      "grey",
      "silver",
      "black",
    ],
    quantity: 0,
    purchase_price: "",
    price: "",
    weight: "",
    text: "",
    color: "",
    snackbar: false,

    headers: [
      { text: "SKU", value: "barcode", sortable: false },
      // { text: "Barcode", value: "barcode", sortable: false },
      { text: "Color", value: "color", sortable: false },
      { text: "Size", value: "size", sortable: false },
      { text: "Weight", value: "weight", sortable: false },
      { text: "Variant", value: "weight_unit", sortable: false },
      { text: "Quantity", value: "quantity", sortable: false },
      // { text: "Purchase Price", value: "purchase_price", sortable: false },
      { text: "Selling Price", value: "new_price", sortable: false },
      // { text: "Share Status", value: "shared", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
      { text: "", value: "adquantity", sortable: false },
      { text: "", value: "sharemother", sortable: false },
      { text: "", value: "status", sortable: false },
    ],

    contract: [],
    specValue: [],
    contact: {},
    editedIndex: -1,
    temp: {},

    addDialog: false,

    districts: [],

    selectedDistrict: "",
    thanas: [],
    selectedThana: [],

    addItem: {
      color: "none",
      size: "none",
      weight: "",
      product_unit: "pcs",
      discount_type: "none",
      discount_amount: "",
      discount_start_date: "",
      discount_end_date: "",
      purchase_price: "",
      price: "",
      point_amount: "",
      point_start_date: "",
      point_end_date: "",
      delivery_product_unit: "gm",
      warranty_unit: "year",
      warranty: "",
      vat: 0.0,
      expire: "",
      manufacture_date: "",
      SKU: "",
      delivery_weight: 0,
      delivery_height: 0,
      delivery_width: 0,
      delivery_length: 0,
      delivery_free: false,
      publish: "",

      option: "all",
      arrayForDelivery: [],
    },

    valid_add_specification: false,

    editedItem: {
      discount: {},
      point: {},
      delivery_info: {},
      price: {},
    },

    validEditForm: false,

    defaultItem: {
      units: "",
      sizes: "",
      colors: "",
      color: "none",
      size: "none",
      discount_amount: "",
      discount_start_date: "",
      discount_end_date: "",
      purchase_price: "",
      price: "",
      point_amount: "",
      point_start_date: "",
      point_end_date: "",
      SKU: "",
      expire: "",
    },

    addedwarehousequantity: [],
    addedshopquantity: [],
    quantityIndex: -1,
    quantityID: -1,

    text: "",
    snackbar: false,

    user: {},

    price: {},
    EditArrayForDelivery: [],

    mrp: "",
    specprice: [
      {
        quantity: 1,
        status: "Single",
        purchase_price: "",
        selling_price: "",
      },
      {
        quantity: "",
        status: "Minimum",
        purchase_price: "",
        selling_price: "",
      },
      {
        quantity: "",
        status: "Maximum",
        purchase_price: "",
        selling_price: "",
      },
    ],

    child_details: {
      max_min: [],
      //  input_mrp:max_min[0].mrp,
    },

    quantitySpec : {}
  }),

  watch: {
    addquantitydialog: function (newValue) {
      this.addedwarehousequantity.length = 0;
      this.addedshopquantity.length = 0;

      // IF add quantity and price modal is closed then reset all the variable
      if (!newValue) {
        this.quantityIndex = -1;
        this.quantityID = -1;
        this.$refs.priceForm.reset();
      }
    },
  },

  methods: {
    // Add specification modal
    addnew() {
      this.addDialog = true;
    },

    // Get all specifications
    initialize() {
      axios
        .get(`productdetails/showspec/${this.$route.query.id}/`)
        .then((response) => {
          if (Array.isArray(response.data.data)) {
            this.contract = response.data.data;
            // console.log("specification value", this.contract);
          }
          // if(response.data.success){
          //   this.specValue=response.data.data

          // }
          this.product_id = this.$route.query.id;
          this.unit = this.$route.unit;
          this.product_title = response.data.product_title;
          this.loading = false;
        });
    },

    // Open add quantity modal
    ADDQuantity(item) {
      this.quantityID = item.id;
      this.quantitySpec = item
      this.quantityIndex = this.contract.findIndex(
        (spec) => spec.id == item.id
      );

      let URL = null

      if (item.is_group) {
        URL = 'productdetails/group_quantity_info'
      }else{
         URL = 'productdetails/quantity_info'
      }

      axios
        .get(`${URL}/${item.id}/`)
        .then((response) => {
          if (response.data.success) {
            this.shop = response.data.data.shop;
            this.warehouse = response.data.data.warehouse;
            this.addquantitydialog = true;
          } else {
            this.color = "red";
            this.text = "Something went wrong !";
            //this.text = response.data.message;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.color = "red";
          this.text = "Something went wrong !";
          //this.text = response.data.message;
          this.snackbar = true;
        });
    },

    AddPrice(item) {
      // console.log({ item });
      let routeData = this.$router.resolve({
        name: `/supportdashboard/pages/add-price/`,
      });
      routeData.href = `/supportdashboard/pages/add-price/?id=${item.id}`;
      window.open(routeData.href, "_blank");
    },

    // If delivery option changed
    deliveryOptionChanged(deliveryOption) {
      this.addItem.arrayForDelivery.length = 0;

      // Create an object for manual location
      if (deliveryOption != "all") {
        this.addItem.arrayForDelivery.push({
          selectedDistrict: "",
          selectedThana: [],
          thanas: [],
          active: false,
        });
      }
    },

    // Increase array for delivery locations
    increaseDeliveryLength(index) {
      this.addItem.arrayForDelivery[index].active = true;
      if (this.districts.length == this.addItem.arrayForDelivery.length) {
        this.text = "Please delete an district first";
        this.color = "warning";
        this.snackbar = true;
      } else {
        this.addItem.arrayForDelivery.push({
          selectedDistrict: "",
          selectedThana: [],
          thanas: [],
          active: false,
        });
      }
    },

    // Decrease array for delivery locations
    deleteDeliveryLength(index) {
      this.addItem.arrayForDelivery.splice(index, 1);
    },

    // get thanas if user select an District
    onSelectDistrict(delivery, index) {
      // this.districtsArrayForCalculation = this.districtsArrayForCalculation.filter(
      //   (element) =>
      //     element.Area_name !=
      //     this.addItem.arrayForDelivery[
      //       this.addItem.arrayForDelivery.length - 1
      //     ].selectedDistrict
      // );

      // Make API call to fetch available thana under selected district

      axios
        .get(`supports/getlocation/${delivery.selectedDistrict}`)
        .then((response) => {
          this.addItem.arrayForDelivery[index].thanas = response.data.data;
        });
    },

    // Create an specification
    save(status) {
      //console.log("this is for test", this.addItem)
      this.addItem.uid = this.user.user_id;
      this.addItem.delivery_weight = Number(this.addItem.delivery_weight);
      this.addItem.delivery_height = Number(this.addItem.delivery_height);
      this.addItem.delivery_width = Number(this.addItem.delivery_width);
      this.addItem.delivery_length = Number(this.addItem.delivery_length);
      this.addItem.vat = Number(this.addItem.vat);
      this.addItem.publish = status;

      //console.log("ADD ITEM " , this.addItem)

      axios
        .post(
          `productdetails/add_specification/${this.$route.query.id}/`,
          this.addItem
        )
        .then((response) => {
          // console.log("res", response.data);
          if (response.data.success) {
            this.addDialog = false;
            this.text = response.data.message;
            this.color = "success";
            this.snackbar = true;

            setTimeout(() => {
              this.$router.go(this.$router.currentRoute);
            }, 1000);
          } else {
            this.text = "Something went wrong !!";
            // this.text = response.data.message;
            this.color = "error";
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.text = "Something went wrong !!";
          this.color = "error";
          this.snackbar = true;
        });
    },

    // Assign item for edit
    assignEditItem(item) {
      this.editedIndex = this.contract.indexOf(item);

      if (item.delivery_location.length) {
        item.option = "manual";
      } else {
        item.option = "all";
      }

      this.editedItem = Object.assign({}, item);

      this.EditArrayForDelivery = JSON.parse(
        JSON.stringify(this.editedItem.delivery_location)
      );

      this.EditArrayForDelivery.forEach((element) => {
        element.active = false;
      });

      this.dialog = true;
    },

    // If delivery option changed
    deliveryOptionChangedOnedit(deliveryOption) {
      // this.EditArrayForDelivery = [];

      // // Create an object for manual location
      if (deliveryOption != "all" && !this.EditArrayForDelivery.length) {
        this.EditArrayForDelivery.push({
          selectedDistrict: "",
          selectedThana: [],
          thanas: [],
          active: false,
        });
      }
    },

    increaseDeliveryLengthOnedit(index) {
      // console.log("I am called ");
      this.EditArrayForDelivery.push({
        selectedDistrict: "",
        selectedThana: [],
        thanas: [],
      });
    },

    deleteDeliveryLengthOnedit(index) {
      this.EditArrayForDelivery.splice(index, 1);
    },

    onSelectDistrictOnEdit(delivery, index) {
      axios
        .get(`supports/getlocation/${delivery.selectedDistrict}`)
        .then((response) => {
          this.EditArrayForDelivery[index].thanas = response.data.data;
        });
    },

    // edit an specification
    saveEdit() {
      let editedSpecification = {
        color: this.editedItem.color,
        delivery_height: this.editedItem.delivery_info.height,
        delivery_inside_city_charge: this.editedItem.delivery_info
          .charge_inside,
        delivery_length: this.editedItem.delivery_info.length,
        delivery_outside_city_charge: this.editedItem.delivery_info
          .charge_outside,
        delivery_product_unit: this.editedItem.delivery_info.measument_unit,
        delivery_weight: this.editedItem.delivery_info.weight,
        delivery_width: this.editedItem.delivery_info.width,
        discount_amount: this.editedItem.discount.amount,
        discount_end_date: this.editedItem.discount.end_date,
        discount_start_date: this.editedItem.discount.start_date,
        discount_type: this.editedItem.discount.discount_type,
        point_amount: this.editedItem.point.point,
        point_end_date: this.editedItem.point.end_date,
        point_start_date: this.editedItem.point.start_date,
        price: this.editedItem.price.price,
        product_unit: this.editedItem.unit,
        purchase_price: this.editedItem.price.purchase_price,
        size: this.editedItem.size,
        warranty: this.editedItem.warranty,
        warranty_unit: this.editedItem.warranty_unit,
        weight: this.editedItem.weight,
        vat: this.editedItem.vat,
        SKU: this.editedItem.SKU,
        expire: this.editedItem.expire,
        manufacture_date: this.editedItem.manufacture_date,
        arrayForDelivery: this.EditArrayForDelivery,
        delivery_option: this.editedItem.option,
        weight_unit: this.editedItem.weight + this.editedItem.unit,
        delivery_free: this.editedItem.delivery_info.delivery_free,
      };

      axios
        .post(
          `productdetails/edit_specification/${this.editedItem.id}/`,
          editedSpecification
        )
        .then((response) => {
          if (response.data.success) {
            this.dialog = false;
            this.text = "Specification edited Successfully";
            this.color = "success";
            this.snackbar = true;
            setTimeout(() => {
              this.$router.go(this.$router.currentRoute);
            }, 2000);
          } else {
            //this.text = "Something went wrong !!";
            this.text = response.data.message;
            this.color = "error";
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.text = "Something went wrong !!";
          //this.text = response.data.message;
          this.color = "error";
          this.snackbar = true;
        });
    },

    // Delete an specification
    deleteItem(item) {
      if (confirm("Do you really want to delete?")) {
        axios
          .post(`productdetails/delete_specification/${item.id}/`)
          .then((response) => {
            this.contract = this.contract.filter(
              (contact) => contact.id !== item.id
            );
          });
      }
    },

    // Add a new warhouse if user give quantity
    warehouseAdd(item) {
      //check array length for new item or not
      if (this.addedwarehousequantity.length > 0) {
        let index = this.addedwarehousequantity.findIndex(
          (warhouse) => warhouse.warehouse_id == item.warehouse_id
        );

        if (index != -1) {
          this.addedwarehousequantity[index].quantity = item.quantity;
        } else {
          this.addedwarehousequantity.push(item);
        }
      } else {
        this.addedwarehousequantity.push(item);
      }
    },

    // Add a new Shop if user give quantity
    shopAdd(item) {
      if (this.addedshopquantity.length > 0) {
        let index = this.addedshopquantity.findIndex(
          (shop) => shop.shop_id == item.shop_id
        );
        if (index != -1) {
          this.addedshopquantity[index].quantity = item.quantity;
        } else {
          this.addedshopquantity.push(item);
        }
      } else {
        this.addedshopquantity.push(item);
      }
    },

    // Quantity and Price addition
    confirmquantity() {
      let datas = {
        product_id: this.contract[0].product_id,
        specification_id: this.quantityID,
        warehouse: this.addedwarehousequantity,
        shop: this.addedshopquantity,
        purchase_price: Number(this.price.purchase_price),
        selling_price: Number(this.price.price),
      };

      let total = 0;

      datas.warehouse.forEach((element) => {
        total = Number(total) + Number(element.quantity);
      });

      datas.shop.forEach((element) => {
        total = Number(total) + Number(element.quantity);
      });

      // If user did not add any quantity throw error
      if (!total) {
        this.text = "Quantity can not be empty";
        this.color = "warning";
        this.snackbar = true;
      } else {
        // If quantity and price exists
        // /productdetails/insert_quantity/

        axios
          .post(
            `productdetails/insert_specification_quantity/${this.user.user_id}/`,
            datas
          )
          .then((response) => {
            if (response.data.success) {
              // this.contract[this.quantityIndex].quantity =
              //   this.contract[this.quantityIndex].quantity + total;
              this.text = "Quantity has been updated successfully";
              this.color = "success";
              this.addquantitydialog = false;
              this.snackbar = true;
            } else {
              this.text = "Something went wrong ";
              this.color = "warning";
              this.addquantitydialog = false;
              this.snackbar = true;
            }
          })
          .catch((err) => {
            this.text = "Something went wrong ";
            this.color = "warning";
            this.addquantitydialog = false;
            this.snackbar = true;
          });
      }
    },
    ShareMS(item) {
      this.ShareProductmodel = true;
      this.temp = item;
    },
    UpdateShare() {},
    Cancelshare() {
      this.ShareProductmodel = false;
    },
    SaveShare(temp) {
      // console.log("this is for test" , temp)
      this.specprice.forEach((element) => {
        element.purchase_price = this.temp.purchase_price;
      });
      let data = {
        MRP: this.mrp,
        data_array: this.specprice,
      };
      // console.log("data array" , data)
      axios
        .post(
          `productdetails/insert_specification_price/${this.temp.id}/`,
          data
        )
        .then((response) => {
          if (response.data.success) {
            this.ShareProductmodel = false;
            this.text = response.data.message;
            this.color = "success";
            this.snackbar = true;
            window.location.reload();
          } else {
            this.text = "something Want Wrong!";
            this.color = "red";
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.text = "Something went wrong !!";
          //this.text = response.data.message;
          this.color = "error";
          this.snackbar = true;
        });
    },

    action(item) {
      const index = this.contract.indexOf(item);

      axios
        .get(`product/publish_unpublish_specification/${item.id}/`)
        .then((response) => {
          this.contract[index].specification_status =
            response.data.specification_status;
          window.location.reload(true);
        });
    },
    ShareUpdate(item) {
      this.shareup = true;

      Object.assign(this.child_details, item);
      // console.log("this is item", this.child_details);
    },
    CancelupdateShare() {
      this.shareup = false;
    },
    SaveUpdateShare(child_details) {
      child_details.max_min.forEach((element) => {
        element.mrp = this.child_details.max_min[0].mrp;
      });
      // console.log("asjnlksdl" , child_details.max_min , this.child_details.id)

      axios
        .post(
          `productdetails/update_max_min_values/${this.child_details.id}/`,
          child_details.max_min
        )
        .then((response) => {
          if (response.data.success) {
            this.ShareProductmodel = false;
            this.text = response.data.message;
            this.color = "success";
            this.snackbar = true;
            window.location.reload();
          } else {
            this.text = "something Want Wrong!";
            this.color = "red";
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.text = "Something went wrong !!";
          //this.text = response.data.message;
          this.color = "error";
          this.snackbar = true;
        });
    },

    ViewBarcode(item) {
      let routeData = this.$router.resolve({
        name: `/supportdashboard/pages/specification_barcode/${item.id}`,
      });
      routeData.href = `/supportdashboard/pages/specification_barcode/?id=${item.id}&unit=${item.unit}`;
      window.open(routeData.href, "_blank");
    },
    viewAllShared() {
      let routeData = this.$router.resolve({
        name: `/supportdashboard/pages/report/`,
      });
      routeData.href = `/supportdashboard/pages/report/`;
      window.open(routeData.href, "_blank");
    },
  },

  mounted() {
    this.initialize();
    this.user = JSON.parse(localStorage.getItem("user"));

    // Fetch all available Districts
    axios.get(`supports/all_areas/`).then((response) => {
      if (response.data.success) {
        this.districts = response.data.data;
        // keep refernce
        this.districtsArrayForCalculation = response.data.data;

        //  this.districts = [
        //    {
        //      Area_name : "Dhaka"
        //    },
        //    {
        //      Area_name : "B"
        //    },
        //    {
        //      Area_name : "C"
        //    }
        //  ];
        // // keep refernce
        // this.districtsArrayForCalculation = this.districts
      }
    });
  },
};
</script>
<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>
